<template lang="pug">
v-container
  v-col(cols="12")
    video
</template>

<script>
export default {
}
</script>

<style>

</style>